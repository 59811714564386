import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/nonun/...Active Code/new-gatsby-site/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Reading review`}</h2>
    <p>{`Where do I begin. This week I decided to get into the `}<em parentName="p">{`Mistborn`}</em>{` series by Brandon Sanderson. I wouldn't say that I have read a great number of book but of the books that I have read, by far, Book/Mistborn/The Final Empire is the best book. The characters feel alive and so does the story! There is foreshadowing of upcoming events like hinting at the abilities of the big baddy which gives you immense satisfaction if you manage to catch that line of thought. Now, the second book which I decided to read was Book/The Psychology of Money and it is also a great book! It talks about some things that I have been thinking about a lot lately like the fact that seemingly poor decisions made by people are actually very reasonable to them in the moment. And the roles of luck and risk inside people's financial outcomes and outcomes in life in general. The author talks about how there is always luck and risk involved in every decision. If the decision but the person just happens to be on the wrong side of probability, then that seemingly good decision can ruin his life. And very poor financial decisions can turn people into billionaires just by a stroke of luck. So when we are trying to learning from people, we should not look at individual case studies but rather we should notice trends. My in depth notes will be coming soon.`}</p>
    <h2>{`Weekly favourites`}</h2>
    <p><strong parentName="p">{`An activity`}</strong>{` - The activity that I am talking about today is Journaling. I have been journaling pretty much every day since the 30th of March. I have had quite a few downs in this journey, especially because I am often sickly. But for the most part I have journaled at least over 200 words over these days. My average nowadays is about 300 to 400 words in my evening journal everyday although I have written quite a few of 1000 words journals as well. I mention journaling write now because this week I decided to read my journals. Apart from the nostalgia, I have experienced quite a few other practical benefits as well.`}</p>
    <h2>{`Quote of the week`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3d039f137536cd25b4c3867766f997eb/061c7/Quote-9.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABwUlEQVQoz4VS207bQBDN/z8hcnHsJIKAClL71g9oqONrKOkTEqWtKurE9voWR71R8O7p7DiAqCp1paMzOzs+e2Y9HaUUoBoAkrBjvX8A5+VT/Jh/fs46tDpKStSXrxF7EyTBFMX5EWL/ECKcol6+QLY4IkwhCLF/gDRoz8qLY6TEwh+jvp6xmFISHSkbJOcniO0e4rmBtT1gTp0hxzezHnLfxOrtAJln8j5xDBSBRXEf0ayLhC5qfv98cNigWJ6i8PqoQgu5N2QI10AZDOkinTdJzIBwBpQz+Sz3DK7LnC5+fH4DrfMoKN6dYnW2T8UjJOROuFTIoq1L7agMR+xcO11RTpDbxDGxnu3hV/axbZmejwWz5Ut8Petj7VhIPRJ1yWk4oT21Om8/zH2LRMdcF3H7Fue16+0nm9+PHZIsNu9PIOw95E6PUfl9JPY+tiG5nXfJuYVqMUHmj+gnWAwd65wgXlN3t5vo6S+Li1dIXRPF4gAROYpdKiLOgjG7va1Xu5YadvIMUo+SHhnF1NHzU8RfkEUfCNdIb66Y775luP+eM5S8QztlgNoB/2LSYsFtvUVVbRh5nqMsK/y9dN3/oNcfoHjMcUEcu98AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Quote",
            "title": "Quote",
            "src": "/static/3d039f137536cd25b4c3867766f997eb/5a190/Quote-9.png",
            "srcSet": ["/static/3d039f137536cd25b4c3867766f997eb/772e8/Quote-9.png 200w", "/static/3d039f137536cd25b4c3867766f997eb/e17e5/Quote-9.png 400w", "/static/3d039f137536cd25b4c3867766f997eb/5a190/Quote-9.png 800w", "/static/3d039f137536cd25b4c3867766f997eb/c1b63/Quote-9.png 1200w", "/static/3d039f137536cd25b4c3867766f997eb/061c7/Quote-9.png 1216w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Tweet of the week`}</h2>
    <blockquote className="twitter-tweet" data-dnt="true">
  <p lang="en" dir="ltr">
    Yesterday
    <a href="https://twitter.com/RoamResearch?ref_src=twsrc%5Etfw">
      @RoamResearch
    </a>
    showed us the very useful new path based find or create tool. Their site is
    updated with a brief summary of the feature.
    <a href="https://twitter.com/hashtag/roamcult?src=hash&amp;ref_src=twsrc%5Etfw">
      #roamcult
    </a>
    <br />
    <br />
    Commit these to muscle memory :-)
    <a href="https://t.co/byDiJMux2S">pic.twitter.com/byDiJMux2S</a>
  </p>
  &mdash; RoamHacker 👨‍🔧 ((roam42.com)) (@roamhacker)
  <a href="https://twitter.com/roamhacker/status/1330588873412538372?ref_src=twsrc%5Etfw">
    November 22, 2020
  </a>
    </blockquote>
    <h2>{`Books that I will be finishing this week`}</h2>
    <p>{`I have consumed a number of non-fiction books over the months but I haven't taken notes on many of them. So this week I intend to not read new content but rather re-read old books and take notes on them. So with this in mind I will be reading Book/Atomic Habits`}</p>
    <center>
      <p><a parentName="p" {...{
          "href": "https://www.amazon.com/Atomic-Habits-Proven-Build-Break/dp/0735211299/ref=sr_1_2?dchild=1&keywords=Atomic+Habits&qid=1606640108&sr=8-2"
        }}><span parentName="a" {...{
            "className": "gatsby-img-attributes",
            "style": {
              "display": "inline-block",
              "width": "325px",
              "height": "500px"
            }
          }}><span parentName="span" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "800px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "150.99999999999997%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAgABBf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAB7IW4rhVkibbH/8QAGhABAQEBAAMAAAAAAAAAAAAAAQARAhASIf/aAAgBAQABBQJtfAWNjet8uUR2zqAL/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8BhT//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwEh/8QAGxAAAgMAAwAAAAAAAAAAAAAAACEBEDERQoH/2gAIAQEABj8CUGVpt6KRcenUUH//xAAgEAEAAgECBwAAAAAAAAAAAAABABEhMVEQYXGBkaGx/9oACAEBAAE/IWmo9pny66MNImzgOb7iDNyoXywuFnmjb+obQE//2gAMAwEAAgADAAAAEP8AGkz/xAAXEQEBAQEAAAAAAAAAAAAAAAAAEQFx/9oACAEDAQE/EOkU2V//xAAYEQEBAQEBAAAAAAAAAAAAAAABABEhUf/aAAgBAgEBPxDXy2QwOdv/xAAgEAEAAgIBBAMAAAAAAAAAAAABABEhMUFhscHwcZHR/9oACAEBAAE/EK0S3Yk+MO4UrFm6J7ylGkvOdwdZFtaazzFD9493K8Ha8QhTLVXe5YUr6jsdjpARh6niVOF6yJT3Uo8N0HM//9k=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "atomic-habits",
                "title": "atomic-habits",
                "src": "/static/f64cf646b03f14e46327e4f9e833f51a/4b190/atomic-habits.jpg",
                "srcSet": ["/static/f64cf646b03f14e46327e4f9e833f51a/e07e9/atomic-habits.jpg 200w", "/static/f64cf646b03f14e46327e4f9e833f51a/066f9/atomic-habits.jpg 400w", "/static/f64cf646b03f14e46327e4f9e833f51a/4b190/atomic-habits.jpg 800w", "/static/f64cf646b03f14e46327e4f9e833f51a/e5166/atomic-habits.jpg 1200w", "/static/f64cf646b03f14e46327e4f9e833f51a/b17f8/atomic-habits.jpg 1600w", "/static/f64cf646b03f14e46327e4f9e833f51a/53d10/atomic-habits.jpg 1696w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
    `}</span></span></a></p>
    </center>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      